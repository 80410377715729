import validate from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.0_rollup@4.18.0_stylelint@16.2.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.9_@vue+compiler-core@3.4.21_nuxt@3.10.3_p_l72xqjd6e7vo4tuqguhlxctiwm/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.0_rollup@4.18.0_stylelint@16.2.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.2_@nuxt+devtools@1.3.9_@vue+compiler-core@3.4.21_nuxt@3.10.3__i3i5lw6rics6yyceikb6d7reg4/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts"),
  auth: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_@opentelemetry+api@1.9.0_next-auth@4.21.1_rollup@4.18.0/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}