import {nl} from '@formkit/i18n';
import {createConfig} from '@formkit/core';
import {generateClasses} from '@formkit/themes';
import {createProPlugin, inputs} from '@formkit/pro-unlimited';
import {addAsteriskPlugin} from '~/utils/formkit/asterisk-plugin';
import {createInput} from '@formkit/vue';
import {FormkitEditor, FormkitImage} from '#components';

const pro = createProPlugin('fk-659793dfad', inputs);

export default createConfig({
  plugins: [pro, addAsteriskPlugin],
  locales: {nl},
  locale: 'nl',
  icons: {
    star: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill="currentColor" d="M9.105 1.383c.422-.844 1.618-.809 2.004 0l2.32 4.676 5.133.738c.915.14 1.266 1.266.598 1.933l-3.691 3.622.879 5.097c.14.914-.844 1.617-1.653 1.195l-4.57-2.425-4.605 2.425c-.81.422-1.793-.28-1.653-1.195l.88-5.097L1.054 8.73c-.668-.668-.317-1.793.597-1.933l5.168-.738 2.285-4.676Z"/></svg>',
    search: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 15"><path fill="currentColor" d="M13.89 13.574c.137.137.137.356 0 .465l-.628.629c-.11.137-.328.137-.465 0l-3.309-3.309a.441.441 0 0 1-.082-.246v-.355a5.758 5.758 0 0 1-3.719 1.367A5.683 5.683 0 0 1 0 6.437C0 3.32 2.543.75 5.688.75c3.117 0 5.687 2.57 5.687 5.688a5.726 5.726 0 0 1-1.395 3.718h.356c.082 0 .164.055.246.11l3.309 3.308Zm-8.203-2.761a4.37 4.37 0 0 0 4.375-4.376 4.388 4.388 0 0 0-4.374-4.375 4.37 4.37 0 0 0-4.375 4.376 4.353 4.353 0 0 0 4.375 4.375Z"/></svg>',
    select: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6"><path fill="currentColor" d="M5.13 5.27 1.08 1.25c-.13-.11-.13-.33 0-.47l.55-.52a.31.31 0 0 1 .46 0L5.38 3.5 8.63.26c.14-.14.35-.14.46 0l.55.52c.14.14.14.36 0 .47L5.6 5.27a.31.31 0 0 1-.46 0Z"/></svg>',
    marker: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 11 15"><path fill="currentColor" d="M5.25.75C8.121.75 10.5 3.102 10.5 6c0 1.969-.656 2.57-4.156 8.148-.52.82-1.696.82-2.215 0C.629 8.57 0 7.968 0 6A5.251 5.251 0 0 1 5.25.75Zm0 12.688C8.75 7.804 9.188 7.503 9.188 6c0-2.16-1.778-3.938-3.938-3.938A3.938 3.938 0 0 0 1.312 6c0 1.504.438 1.86 3.938 7.438ZM3.062 6c0-1.203.958-2.188 2.188-2.188 1.203 0 2.188.985 2.188 2.188 0 1.23-.985 2.188-2.188 2.188A2.16 2.16 0 0 1 3.062 6Z"/></svg>',
    checkboxDecorator: '<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M11.8945 1.04688C12.0312 0.910156 12.25 0.910156 12.3594 1.04688L13.1523 1.8125C13.2617 1.94922 13.2617 2.16797 13.1523 2.27734L4.94922 10.4805C4.8125 10.6172 4.62109 10.6172 4.48438 10.4805L0.820312 6.84375C0.710938 6.70703 0.710938 6.48828 0.820312 6.37891L1.61328 5.58594C1.72266 5.47656 1.94141 5.47656 2.07812 5.58594L4.70312 8.23828L11.8945 1.04688Z" fill="white"/>\n' +
      '</svg>\n',
  },
  config: {
    classes: generateClasses({
      global: {
        help: 'text-ss',
        input: `
          block p-3 px-4 formkit-prefix-icon:pl-2.5
          bg-transparent
          outline-none focus:ring-0
          w-full
          placeholder:text-gray-400
        `,
        inner: `
          rounded-xl overflow-clip
          bg-white text-gray-800
          border border-black-400
          formkit-invalid:border-red
          formkit-invalid:focus-within:border-secondary-900
          focus-within:border-secondary-900
          formkit-disabled:opacity-60
          formkit-disabled:bg-black-300
          border border-gray-300 hover:border-gray-400 focus-within:!border-orange
          transition
          formkit-prefix-icon:flex
        `,
        label: 'text-sm',
        messages: 'list-none p-0 mt-1 mb-3 text-xs text-red',
        message: '',
        outer: 'mb-3 last:mb-0',
        wrapper: 'flex flex-col gap-1',
        prefixIcon: 'ml-4 flex self-stretch grow-0 shrink-0 rounded-tl rounded-bl bg-white [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
      },
      'family:box': {
        decorator: 'flex items-center justify-center relative w-5 h-5 rounded bg-white border border-gray-300 text-white group-focus-within:border-secondary-500 group-hover:border-secondary-500 transition formkit-checked:bg-secondary-500 formkit-checked:!border-secondary',
        input: 'accent-primary w-0 h-0 opacity-0 pointer-events-none',
        label: '$reset text-gray-700',
        wrapper: '$reset transition group flex gap-3 items-center p-3 formkit-checked:bg-secondary-100 cursor-pointer',
      },
      'family:button': {
        input: '$reset button bg-orange border-orange hover:border-orange-700 hover:bg-orange-700 focus:bg-orange-800 text-white px-4 py-2 rounded-full border text-center text-base font-semibold no-underline transition-all disabled:cursor-not-allowed disabled:opacity-30 disabled:saturate-0',
        wrapper: '$reset',
      },
      'family:text': {
        input: 'w-full py-3.5',
      },
      checkbox: {
        inner: '$reset',
      },
      file: {
        fileItem: 'block flex text-sm',
        fileRemove: 'ml-auto text-sm',
        input: 'w-full cursor-pointer file:cursor-pointer focus:ring-0 focus:outline-none text-sm file:focus:outline-none focus:border-none focus:shadow-none file:mr-4 file:py-3 file:px-6 file:tracking-widest file:rounded-lg file:border-0 file:btn file:text-xs file:bg-tertiary file:text-white hover:file:bg-tertiary-hover',
        label: 'block mb-1 font-bold',
        noFiles: 'block text-sm',
      },
      search: {
        prefixIcon: 'text-gray-600 block w-3.5',
      },
      select: {
        input: 'appearance-none bg-select bg-[length:16px_auto] bg-no-repeat bg-[center_right_1em] py-3.5 !pr-10 min-w-max',
        inner: 'rounded-xl flex items-center gap-2.5 relative',
        selectIcon: 'absolute right-6 top-1/2 -translate-y-1/2 text-gray-600 block w-2.5',
      },
      textarea: {
        input: 'w-full min-h-[240px]',
      },
      rating: {
        inner: 'relative flex items-center w-[8em] formkit-disabled:bg-transparent border-0',
        itemsWrapper: 'w-full',
        onItems: 'text-orange cursor-pointer',
        onItemWrapper: '[&>*]:w-full [&>svg]:h-auto [&>svg]:max-w-none [&>svg]:max-h-none',
        offItems: 'text-gray-700 cursor-pointer',
        offItemWrapper: '[&>*]:w-full [&>svg]:h-auto [&>svg]:max-w-none [&>svg]:max-h-none',
      },
      image: {
        inner: 'border-none rounded-none !rounded-none',
      },
    }),
  },
  inputs: {
    editor: createInput(FormkitEditor, {
      family: 'text',
      props: ['actions-after'],
    }),
    image: createInput(FormkitImage, {
      props: ['title', 'subtitle'],
    }),
  },
});
