<template>
  <div class="@container">
    <div
      v-if="editor"
      class="flex w-full items-center divide-x divide-gray-300 rounded-t-xl border border-gray-300"
    >
      <div class="group relative flex flex-1 items-center">
        <div class="editor-button large flex !justify-between overflow-clip rounded-tl-xl">
          Normale tekst
          <UiIcon
            name="ChevronDown"
            size="tiny"
          />
        </div>
        <div
          class="absolute top-full z-10 hidden w-full rounded-b-xl border border-t-0 border-gray-300 bg-white group-hover:block"
        >
          <button
            class="editor-button large as-h1 mb-0"
            @click="editor.chain().focus().toggleHeading({level: 1}).run()"
          >
            H1
          </button>
          <button
            class="editor-button large as-h2 mb-0"
            @click="editor.chain().focus().toggleHeading({level: 2}).run()"
          >
            H2
          </button>
          <button
            class="editor-button large as-h3 mb-0"
            @click="editor.chain().focus().toggleHeading({level: 3}).run()"
          >
            H3
          </button>
          <button
            class="editor-button large"
            @click="editor.commands.setParagraph()"
          >
            Normale tekst
          </button>
        </div>
      </div>

      <div class="flex flex-1 items-center justify-center">
        <button
          class="editor-button"
          :class="{'is-active': editor.isActive('bold')}"
          @click="editor.chain().focus().toggleBold().run()"
        >
          <UiIcon
            name="Bold"
            size="small"
          />
        </button>
        <button
          class="editor-button"
          :class="{'is-active': editor.isActive('italic')}"
          @click="editor.chain().focus().toggleItalic().run()"
        >
          <UiIcon
            name="Italic"
            size="small"
          />
        </button>
        <button
          class="editor-button"
          :class="{'is-active': editor.isActive('underline')}"
          @click="editor.chain().focus().toggleUnderline().run()"
        >
          <UiIcon
            name="Underline"
            size="small"
          />
        </button>
      </div>

      <div class="flex flex-1 items-center justify-center max-sm:hidden">
        <button
          class="editor-button"
          :class="{'is-active': editor.isActive('bulletList')}"
          @click="editor.chain().focus().toggleBulletList().run()"
        >
          <UiIcon
            name="UnorderedList"
            size="small"
          />
        </button>
        <button
          class="editor-button"
          :class="{'is-active': editor.isActive('orderedList')}"
          @click="editor.chain().focus().toggleOrderedList().run()"
        >
          <UiIcon
            name="OrderedList"
            size="small"
          />
        </button>
        <div class="group relative">
          <div class="editor-button">
            <UiIcon
              v-if="editor.isActive({textAlign: 'left'})"
              name="TextAlignLeft"
              size="small"
            />
            <UiIcon
              v-else-if="editor.isActive({textAlign: 'center'})"
              name="TextAlignCenter"
              size="small"
            />
            <UiIcon
              v-else-if="editor.isActive({textAlign: 'right'})"
              name="TextAlignRight"
              size="small"
            />
          </div>
          <div
            class="absolute top-full z-10 hidden rounded-b-xl border border-t-0 border-gray-300 bg-white group-hover:block"
          >
            <button
              class="editor-button"
              :class="{'is-active': editor.isActive({textAlign: 'left'})}"
              @click="editor.chain().focus().setTextAlign('left').run()"
            >
              <UiIcon
                name="TextAlignLeft"
                size="small"
              />
            </button>
            <button
              class="editor-button"
              :class="{'is-active': editor.isActive({textAlign: 'center'})}"
              @click="editor.chain().focus().setTextAlign('center').run()"
            >
              <UiIcon
                name="TextAlignCenter"
                size="small"
              />
            </button>
            <button
              class="editor-button"
              :class="{'is-active': editor.isActive({textAlign: 'right'})}"
              @click="editor.chain().focus().setTextAlign('right').run()"
            >
              <UiIcon
                name="TextAlignRight"
                size="small"
              />
            </button>
          </div>
        </div>
      </div>

      <div class="flex flex-1 items-center justify-center overflow-clip max-md:hidden md:rounded-tr-xl">
        <button
          class="editor-button"
          :class="{'is-active': editor.isActive('link')}"
          @click="setLink"
        >
          <UiIcon
            name="EditorLink"
            size="small"
          />
        </button>
        <button
          class="editor-button"
          @click="setImage"
        >
          <UiIcon
            name="Image"
            size="small"
          />
        </button>
        <button
          class="editor-button"
          @click="setVideo"
        >
          <UiIcon
            name="Video"
            size="small"
          />
        </button>
      </div>

      <!-- dropdown menu mobile -->
      <div class="group relative -right-px flex items-center self-stretch md:hidden">
        <div class="px-2">
          <UiIcon
            name="ThreeDots"
            size="small"
          />
        </div>
        <div
          class="absolute right-0 top-full z-10 hidden translate-y-px overflow-clip rounded-b-xl border border-t-0 border-gray-300 bg-white group-hover:block"
        >
          <div class="sm:hidden">
            <button
              class="editor-button"
              :class="{'is-active': editor.isActive('bulletList')}"
              @click="editor.chain().focus().toggleBulletList().run()"
            >
              <UiIcon
                name="UnorderedList"
                size="small"
              />
            </button>
            <button
              class="editor-button"
              :class="{'is-active': editor.isActive('orderedList')}"
              @click="editor.chain().focus().toggleOrderedList().run()"
            >
              <UiIcon
                name="OrderedList"
                size="small"
              />
            </button>
          </div>
          <div class="sm:hidden">
            <button
              class="editor-button"
              :class="{'is-active': editor.isActive({textAlign: 'left'})}"
              @click="editor.chain().focus().setTextAlign('left').run()"
            >
              <UiIcon
                name="TextAlignLeft"
                size="small"
              />
            </button>
            <button
              class="editor-button"
              :class="{'is-active': editor.isActive({textAlign: 'center'})}"
              @click="editor.chain().focus().setTextAlign('center').run()"
            >
              <UiIcon
                name="TextAlignCenter"
                size="small"
              />
            </button>
            <button
              class="editor-button"
              :class="{'is-active': editor.isActive({textAlign: 'right'})}"
              @click="editor.chain().focus().setTextAlign('right').run()"
            >
              <UiIcon
                name="TextAlignRight"
                size="small"
              />
            </button>
          </div>
          <button
            class="editor-button"
            :class="{'is-active': editor.isActive('link')}"
            @click="setLink"
          >
            <UiIcon
              name="EditorLink"
              size="small"
            />
          </button>
          <button
            class="editor-button"
            @click="setImage"
          >
            <UiIcon
              name="Image"
              size="small"
            />
          </button>
          <button
            class="editor-button"
            @click="setVideo"
          >
            <UiIcon
              name="Video"
              size="small"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="rounded-b-xl border border-t-0 border-gray-300">
      <EditorContent :editor="editor" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {useEditor, EditorContent} from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Bold from '@tiptap/extension-bold';
import Heading from '@tiptap/extension-heading';
import Italic from '@tiptap/extension-italic';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import UnorderedList from '@tiptap/extension-bullet-list';
import Youtube from '@tiptap/extension-youtube';

const props = withDefaults(defineProps<{
  disabled?: boolean
  name: string
  id?: string
  placeholder?: string
  modelValue: string | undefined
}>(), {
  id: '',
  placeholder: '',
  modelValue: '',
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | undefined): void
  (e: 'blur'): void
}>();

const editor = ref(useEditor({
  content: props.modelValue,
  editable: !props.disabled,
  extensions: [
    StarterKit,
    Bold,
    Heading,
    Italic,
    Image,
    Link.configure({
      openOnClick: false,
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    Underline,
    UnorderedList,
    Youtube,
  ],
  onUpdate({editor}) {
    let editorHtml = editor.getHTML();

    if (props.modelValue !== editorHtml) {
      if (editorHtml === '<p></p>') {
        editorHtml = '';
      }

      emit('update:modelValue', editorHtml);
    }
  },
}));

function setLink() {
  const previousUrl = editor.value?.getAttributes('link').href;
  const url = window.prompt('URL', previousUrl);

  // Cancelled
  if (url === null) {
    return;
  }

  // Empty
  if (url === '') {
    editor.value?.chain()
      .focus()
      .extendMarkRange('link')
      .unsetLink()
      .run();

    return;
  }

  // Update link
  editor.value?.chain()
    .focus()
    .extendMarkRange('link')
    .setLink({href: url})
    .run();
}

function setImage() {
  const url = window.prompt('URL');

  if (url) {
    editor.value?.chain().focus().setImage({src: url}).run();
  }
}

function setVideo() {
  const url = prompt('Enter YouTube URL');

  editor.value?.commands.setYoutubeVideo({
    src: url,
    width: 640,
    height: 360,
  });
}
</script>

<style scoped>
.editor-button {
  @apply w-10 h-12 flex items-center justify-center hover:bg-gray-100 transition text-gray-600 max-sm:text-sm;

  &.is-active {
    @apply bg-primary-100 !text-primary-700;
  }

  &.large {
    @apply w-full justify-start px-5;
  }
}

:deep(.tiptap) {
  @apply p-4 prose prose-headings:mt-0 prose-p:mt-0 max-w-none;
}
</style>
