import { defineNuxtPlugin } from '#app/nuxt'
import { LazyArrowBack, LazyArrowLeft, LazyArrowRight, LazyBold, LazyBriefcase, LazyButterfly, LazyChevronDown, LazyClock, LazyEditorLink, LazyEmailCircle, LazyEnvelope, LazyFacebook, LazyFacebookShare, LazyFilter, LazyGlobe, LazyHamburger, LazyHeart, LazyHeartFilled, LazyImage, LazyInstagram, LazyItalic, LazyLinkedin, LazyLinkedinShare, LazyMarker, LazyMarkerCircle, LazyMinus, LazyOrderedList, LazyPhone, LazyPhoneCircle, LazyPinterest, LazyPlus, LazyReset, LazyStar, LazyStarFilled, LazyTextAlignCenter, LazyTextAlignLeft, LazyTextAlignRight, LazyThreeDots, LazyTiktok, LazyTimes, LazyTrash, LazyUnderline, LazyUnorderedList, LazyUser, LazyVideo, LazyX, LazyXMark, LazyYouTube, LazyCookieFirstPolicy, LazyCookieFirstTable, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["ArrowBack", LazyArrowBack],
["ArrowLeft", LazyArrowLeft],
["ArrowRight", LazyArrowRight],
["Bold", LazyBold],
["Briefcase", LazyBriefcase],
["Butterfly", LazyButterfly],
["ChevronDown", LazyChevronDown],
["Clock", LazyClock],
["EditorLink", LazyEditorLink],
["EmailCircle", LazyEmailCircle],
["Envelope", LazyEnvelope],
["Facebook", LazyFacebook],
["FacebookShare", LazyFacebookShare],
["Filter", LazyFilter],
["Globe", LazyGlobe],
["Hamburger", LazyHamburger],
["Heart", LazyHeart],
["HeartFilled", LazyHeartFilled],
["Image", LazyImage],
["Instagram", LazyInstagram],
["Italic", LazyItalic],
["Linkedin", LazyLinkedin],
["LinkedinShare", LazyLinkedinShare],
["Marker", LazyMarker],
["MarkerCircle", LazyMarkerCircle],
["Minus", LazyMinus],
["OrderedList", LazyOrderedList],
["Phone", LazyPhone],
["PhoneCircle", LazyPhoneCircle],
["Pinterest", LazyPinterest],
["Plus", LazyPlus],
["Reset", LazyReset],
["Star", LazyStar],
["StarFilled", LazyStarFilled],
["TextAlignCenter", LazyTextAlignCenter],
["TextAlignLeft", LazyTextAlignLeft],
["TextAlignRight", LazyTextAlignRight],
["ThreeDots", LazyThreeDots],
["Tiktok", LazyTiktok],
["Times", LazyTimes],
["Trash", LazyTrash],
["Underline", LazyUnderline],
["UnorderedList", LazyUnorderedList],
["User", LazyUser],
["Video", LazyVideo],
["X", LazyX],
["XMark", LazyXMark],
["YouTube", LazyYouTube],
["CookieFirstPolicy", LazyCookieFirstPolicy],
["CookieFirstTable", LazyCookieFirstTable],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
