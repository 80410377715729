<template>
  <component
    :is="to ? NuxtLink : 'button'"
    class="group/button flex items-center justify-center rounded-full border text-center text-base font-semibold no-underline transition-all disabled:cursor-not-allowed disabled:opacity-30 disabled:saturate-0 print:hidden"
    :class="[
      type === 'submit' ? colorVariants['primary'] : colorVariants[type],
      label ? 'w-fit px-4 py-2' : 'flex size-11 items-center justify-center',
      disabled ? 'opacity-30 saturate-0' : ''
    ]"
    :disabled="disabled || loading ? 'disabled' : undefined"
    :target="target || undefined"
    :to="to"
    :type="type === 'submit' ? 'submit' : 'button'"
  >
    {{ label }}
    <UiIcon
      v-if="icon"
      :name="icon"
      :size="iconSize"
    />
    <UiIcon
      v-if="loading"
      :size="iconSize"
      name="svg-spinners:ring-resize"
    />
  </component>
</template>

<script lang="ts" setup>
import {NuxtLink} from '#components';
import type {RouteLocationRaw} from 'vue-router';

const colorVariants = {
  primary: 'bg-orange border-orange hover:border-orange-700 hover:bg-orange-700 focus:bg-orange-800 text-white',
  secondary: 'bg-transparent border-primary hover:bg-primary text-primary hover:text-white focus:bg-primary-800 focus:text-white',
  tertiary: 'bg-primary hover:bg-primary-700 border-primary-600 text-white focus:bg-primary-800 focus:text-white',
};

withDefaults(defineProps<{
  disabled?: boolean
  icon?: string
  iconSize?: 'tiny' | 'small' | 'medium' | 'large'
  label?: string
  loading?: boolean
  target?: string
  to?: RouteLocationRaw,
  type?: 'primary' | 'secondary' | 'tertiary' | 'submit' | 'icon'
}>(), {
  disabled: false,
  icon: undefined,
  iconSize: 'small',
  label: undefined,
  loading: false,
  target: undefined,
  to: undefined,
  type: 'primary',
});
</script>
