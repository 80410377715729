import Vue3Toastify, {toast} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Vue3Toastify, {
    hideProgressBar: true,
    icon: false,
    position: toast.POSITION.TOP_CENTER,
    closeButton: false,

    autoClose: false,
  });

  return {
    provide: {toast},
  };
});
