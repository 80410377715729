<template>
  <div class="text-sm">
    <div class="mb-1">
      <div class="mb-1">
        {{ context.title }}
      </div>
      <p
        v-if="context.subtitle"
        class="text-xs font-semibold"
      >
        {{ context.subtitle }}
      </p>
    </div>
    <div class="max-w-[15.5rem]">
      <div
        v-if="context.value"
        class="mb-3 flex h-36 items-center justify-center overflow-clip rounded-xl border border-gray-300"
        :class="{'p-6': !fullSize}"
      >
        <div
          class="inline-block w-full overflow-clip"
        >
          <img
            v-if="hasUploadedFile"
            :class="!fullSize ? 'w-24 h-24 object-cover rounded-full mx-auto' : 'w-full h-full object-cover mx-auto'"
            :src="uploadedImage"
            :alt="context.node.name"
          >
          <NuxtPicture
            v-else
            :class="!fullSize ? 'w-24 h-24 object-cover rounded-full mx-auto' : 'w-full h-full object-cover mx-auto'"
            :src="uploadedImage"
          />
          <div>
            <input
              :id="context.node.name"
              class="absolute top-0 block size-max cursor-pointer opacity-0"
              type="file"
              :accept="attributes.accept || '*/*'"
              @change="uploadFile"
            >
          </div>
        </div>
      </div>
      <div
        v-else
        class="mb-3 flex h-36 items-center justify-center overflow-clip rounded-xl border border-gray-300"
      >
        <span>Voeg een afbeelding toe</span>
      </div>
      <div class="flex justify-between gap-3">
        <label
          :for="context.node.name"
          class="relative cursor-pointer text-primary-500 underline"
        >
          Upload nieuwe foto
        </label>
        <input
          :id="context.node.name"
          class="top-0 hidden size-full cursor-pointer opacity-0"
          type="file"
          :accept="attributes.accept || '*/*'"
          @change="uploadFile"
        >
        <button
          class="text-primary-500 underline"
          @click="confirmRemoveImage"
        >
          Verwijder foto
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {FormKitFrameworkContext} from '@formkit/core';

const props = defineProps<{
  fullSize?: boolean
  context: FormKitFrameworkContext
}>();

const hasUploadedFile = ref(false);

const attributes = computed(() => Object.assign(props.context?.attrs, {id: null, class: null}));
const maxFileSize = computed(() => attributes.value.maxFileSize || 15); // In MB's

const uploadedImage = computed(() => {
  const imageValue = props.context.value;
  if (!imageValue) {
    return null;
  }

  return (typeof File !== 'undefined' && imageValue instanceof File) ? URL.createObjectURL(imageValue) : imageValue;
});

function uploadFile(event) {
  const input = event.target;

  if (!input.files) {
    return;
  }

  Array.from(input.files).forEach((file) => {
    if (!file) {
      return;
    }

    const sizeInMB = file.size / 1024 / 1024;

    if (sizeInMB > maxFileSize.value) {
      console.error('File exceeded max file limit');

      return;
    }
    const reader = new FileReader();

    reader.onload = () => {
      props.context.node.input(file);
    };

    hasUploadedFile.value = true;
    reader.readAsDataURL(file); // convert to base64 string
  });
}

function confirmRemoveImage() {
  if (!confirm('Weet je zeker dat je de afbeelding wilt verwijderen?')) {
    return;
  }

  props.context.node.input(null);
}
</script>
