import { default as _91_46_46_46page_93Wd2Owgwn64Meta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/[...page].vue?macro=true";
import { default as advertentie_45aanvragenZa0QcD2O5KMeta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/account/advertentie-aanvragen.vue?macro=true";
import { default as bedrijfsinformatie0JJCmlJkhYMeta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/account/bedrijfsinformatie.vue?macro=true";
import { default as bedrijfspaginab5vjyrTOMaMeta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/account/bedrijfspagina.vue?macro=true";
import { default as change_45passwordioTajHvtxoMeta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/account/change-password.vue?macro=true";
import { default as indexORQ14uD6TJMeta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/account/index.vue?macro=true";
import { default as locatiesFt0pdaM2b8Meta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/account/locaties.vue?macro=true";
import { default as agendaaaB3UFCqi8Meta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/agenda.vue?macro=true";
import { default as _91slug_93RcJRxNeCbKMeta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/artikelen/[slug].vue?macro=true";
import { default as indexkHllf47xf4Meta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/artikelen/index.vue?macro=true";
import { default as artikelenVWzDTjdHpwMeta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/artikelen.vue?macro=true";
import { default as loginpefk038QecMeta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/auth/login.vue?macro=true";
import { default as request_45passwordc21WZRo156Meta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/auth/request-password.vue?macro=true";
import { default as _91token_93zSeIEIH4T9Meta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/auth/reset-password/[token].vue?macro=true";
import { default as _91token_93EutgPioda1Meta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/auth/wachtwoord-instellen/[token].vue?macro=true";
import { default as bedrijvengidseRsegnQYjVMeta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/b2b/bedrijvengids.vue?macro=true";
import { default as _91slug_93NTIlSCVzZlMeta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/bedrijven/[slug].vue?macro=true";
import { default as _91category_93rrROIb5fVoMeta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/bedrijven/c/[category].vue?macro=true";
import { default as indexQUwYCBmMklMeta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/bedrijven/index.vue?macro=true";
import { default as contactGKnZMUvcULMeta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/contact.vue?macro=true";
import { default as favorietenia8NLRpm3qMeta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/favorieten.vue?macro=true";
import { default as indexusrNRT9zEoMeta } from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93Wd2Owgwn64Meta?.name ?? "page",
    path: _91_46_46_46page_93Wd2Owgwn64Meta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93Wd2Owgwn64Meta || {},
    alias: _91_46_46_46page_93Wd2Owgwn64Meta?.alias || [],
    redirect: _91_46_46_46page_93Wd2Owgwn64Meta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: advertentie_45aanvragenZa0QcD2O5KMeta?.name ?? "account-advertentie-aanvragen",
    path: advertentie_45aanvragenZa0QcD2O5KMeta?.path ?? "/account/advertentie-aanvragen",
    meta: advertentie_45aanvragenZa0QcD2O5KMeta || {},
    alias: advertentie_45aanvragenZa0QcD2O5KMeta?.alias || [],
    redirect: advertentie_45aanvragenZa0QcD2O5KMeta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/account/advertentie-aanvragen.vue").then(m => m.default || m)
  },
  {
    name: bedrijfsinformatie0JJCmlJkhYMeta?.name ?? "account-bedrijfsinformatie",
    path: bedrijfsinformatie0JJCmlJkhYMeta?.path ?? "/account/bedrijfsinformatie",
    meta: bedrijfsinformatie0JJCmlJkhYMeta || {},
    alias: bedrijfsinformatie0JJCmlJkhYMeta?.alias || [],
    redirect: bedrijfsinformatie0JJCmlJkhYMeta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/account/bedrijfsinformatie.vue").then(m => m.default || m)
  },
  {
    name: bedrijfspaginab5vjyrTOMaMeta?.name ?? "account-bedrijfspagina",
    path: bedrijfspaginab5vjyrTOMaMeta?.path ?? "/account/bedrijfspagina",
    meta: bedrijfspaginab5vjyrTOMaMeta || {},
    alias: bedrijfspaginab5vjyrTOMaMeta?.alias || [],
    redirect: bedrijfspaginab5vjyrTOMaMeta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/account/bedrijfspagina.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordioTajHvtxoMeta?.name ?? "account-change-password",
    path: change_45passwordioTajHvtxoMeta?.path ?? "/account/change-password",
    meta: change_45passwordioTajHvtxoMeta || {},
    alias: change_45passwordioTajHvtxoMeta?.alias || [],
    redirect: change_45passwordioTajHvtxoMeta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/account/change-password.vue").then(m => m.default || m)
  },
  {
    name: indexORQ14uD6TJMeta?.name ?? "account",
    path: indexORQ14uD6TJMeta?.path ?? "/account",
    meta: indexORQ14uD6TJMeta || {},
    alias: indexORQ14uD6TJMeta?.alias || [],
    redirect: indexORQ14uD6TJMeta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: locatiesFt0pdaM2b8Meta?.name ?? "account-locaties",
    path: locatiesFt0pdaM2b8Meta?.path ?? "/account/locaties",
    meta: locatiesFt0pdaM2b8Meta || {},
    alias: locatiesFt0pdaM2b8Meta?.alias || [],
    redirect: locatiesFt0pdaM2b8Meta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/account/locaties.vue").then(m => m.default || m)
  },
  {
    name: agendaaaB3UFCqi8Meta?.name ?? "agenda",
    path: agendaaaB3UFCqi8Meta?.path ?? "/agenda",
    meta: agendaaaB3UFCqi8Meta || {},
    alias: agendaaaB3UFCqi8Meta?.alias || [],
    redirect: agendaaaB3UFCqi8Meta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/agenda.vue").then(m => m.default || m)
  },
  {
    name: artikelenVWzDTjdHpwMeta?.name ?? undefined,
    path: artikelenVWzDTjdHpwMeta?.path ?? "/artikelen",
    meta: artikelenVWzDTjdHpwMeta || {},
    alias: artikelenVWzDTjdHpwMeta?.alias || [],
    redirect: artikelenVWzDTjdHpwMeta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/artikelen.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93RcJRxNeCbKMeta?.name ?? "artikelen-slug",
    path: _91slug_93RcJRxNeCbKMeta?.path ?? ":slug()",
    meta: _91slug_93RcJRxNeCbKMeta || {},
    alias: _91slug_93RcJRxNeCbKMeta?.alias || [],
    redirect: _91slug_93RcJRxNeCbKMeta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/artikelen/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexkHllf47xf4Meta?.name ?? "artikelen",
    path: indexkHllf47xf4Meta?.path ?? "",
    meta: indexkHllf47xf4Meta || {},
    alias: indexkHllf47xf4Meta?.alias || [],
    redirect: indexkHllf47xf4Meta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/artikelen/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: loginpefk038QecMeta?.name ?? "auth-login",
    path: loginpefk038QecMeta?.path ?? "/auth/login",
    meta: loginpefk038QecMeta || {},
    alias: loginpefk038QecMeta?.alias || [],
    redirect: loginpefk038QecMeta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: request_45passwordc21WZRo156Meta?.name ?? "auth-request-password",
    path: request_45passwordc21WZRo156Meta?.path ?? "/auth/request-password",
    meta: request_45passwordc21WZRo156Meta || {},
    alias: request_45passwordc21WZRo156Meta?.alias || [],
    redirect: request_45passwordc21WZRo156Meta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/auth/request-password.vue").then(m => m.default || m)
  },
  {
    name: _91token_93zSeIEIH4T9Meta?.name ?? "auth-reset-password-token",
    path: _91token_93zSeIEIH4T9Meta?.path ?? "/auth/reset-password/:token()",
    meta: _91token_93zSeIEIH4T9Meta || {},
    alias: _91token_93zSeIEIH4T9Meta?.alias || [],
    redirect: _91token_93zSeIEIH4T9Meta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93EutgPioda1Meta?.name ?? "auth-wachtwoord-instellen-token",
    path: _91token_93EutgPioda1Meta?.path ?? "/auth/wachtwoord-instellen/:token()",
    meta: _91token_93EutgPioda1Meta || {},
    alias: _91token_93EutgPioda1Meta?.alias || [],
    redirect: _91token_93EutgPioda1Meta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/auth/wachtwoord-instellen/[token].vue").then(m => m.default || m)
  },
  {
    name: bedrijvengidseRsegnQYjVMeta?.name ?? "b2b-bedrijvengids",
    path: bedrijvengidseRsegnQYjVMeta?.path ?? "/b2b/bedrijvengids",
    meta: bedrijvengidseRsegnQYjVMeta || {},
    alias: bedrijvengidseRsegnQYjVMeta?.alias || [],
    redirect: bedrijvengidseRsegnQYjVMeta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/b2b/bedrijvengids.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NTIlSCVzZlMeta?.name ?? "bedrijven-slug",
    path: _91slug_93NTIlSCVzZlMeta?.path ?? "/bedrijven/:slug()",
    meta: _91slug_93NTIlSCVzZlMeta || {},
    alias: _91slug_93NTIlSCVzZlMeta?.alias || [],
    redirect: _91slug_93NTIlSCVzZlMeta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/bedrijven/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91category_93rrROIb5fVoMeta?.name ?? "bedrijven-c-category",
    path: _91category_93rrROIb5fVoMeta?.path ?? "/bedrijven/c/:category()",
    meta: _91category_93rrROIb5fVoMeta || {},
    alias: _91category_93rrROIb5fVoMeta?.alias || [],
    redirect: _91category_93rrROIb5fVoMeta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/bedrijven/c/[category].vue").then(m => m.default || m)
  },
  {
    name: indexQUwYCBmMklMeta?.name ?? "bedrijven",
    path: indexQUwYCBmMklMeta?.path ?? "/bedrijven",
    meta: indexQUwYCBmMklMeta || {},
    alias: indexQUwYCBmMklMeta?.alias || [],
    redirect: indexQUwYCBmMklMeta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/bedrijven/index.vue").then(m => m.default || m)
  },
  {
    name: contactGKnZMUvcULMeta?.name ?? "contact",
    path: contactGKnZMUvcULMeta?.path ?? "/contact",
    meta: contactGKnZMUvcULMeta || {},
    alias: contactGKnZMUvcULMeta?.alias || [],
    redirect: contactGKnZMUvcULMeta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: favorietenia8NLRpm3qMeta?.name ?? "favorieten",
    path: favorietenia8NLRpm3qMeta?.path ?? "/favorieten",
    meta: favorietenia8NLRpm3qMeta || {},
    alias: favorietenia8NLRpm3qMeta?.alias || [],
    redirect: favorietenia8NLRpm3qMeta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/favorieten.vue").then(m => m.default || m)
  },
  {
    name: indexusrNRT9zEoMeta?.name ?? "index",
    path: indexusrNRT9zEoMeta?.path ?? "/",
    meta: indexusrNRT9zEoMeta || {},
    alias: indexusrNRT9zEoMeta?.alias || [],
    redirect: indexusrNRT9zEoMeta?.redirect,
    component: () => import("/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/pages/index.vue").then(m => m.default || m)
  }
]