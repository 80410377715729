import revive_payload_client_XbULHBwxnS from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.0_rollup@4.18.0_stylelint@16.2.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_siqv8KOX3t from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.0_rollup@4.18.0_stylelint@16.2.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_odDsqjRY5H from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.0_rollup@4.18.0_stylelint@16.2.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_iSHE8CLQVg from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.9_@vue+compiler-core@3.4.21_nuxt@3.10.3_postcss@8._5lntvmxbnbgrdmjwhz2kcbqj6q/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_FsJ3jvDwFk from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.0_rollup@4.18.0_stylelint@16.2.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_ymhhIsVcrJ from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.0_rollup@4.18.0_stylelint@16.2.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_yABJtxGMhs from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.0_rollup@4.18.0_stylelint@16.2.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_peuFlAheSI from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.2.3_rollup@4.18.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import i18n_dFAZsra3L8 from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.0_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/.nuxt/formkitPlugin.mjs";
import plugin_CbMgn1kqLr from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.1_react-dom@18.2.0_react@18.2.0_rollup@4.18.0_typescript@5.3.3_vue@3.4.21/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_pVADGiWzNI from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_@opentelemetry+api@1.9.0_next-auth@4.21.1_rollup@4.18.0/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_client_UI7we2nkjn from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/nuxt-cookie-first@0.1.7_rollup@4.18.0/node_modules/nuxt-cookie-first/dist/runtime/plugin.client.mjs";
import plugin_48LQtm3JhV from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.10.3_rollup@4.18.0_vue@3.4.21/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_LW2wQerJ75 from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.0_rollup@4.18.0_stylelint@16.2.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import errors_5QPuds9rY2 from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.9_@vue+compiler-core@3.4.21_nuxt@3.10.3_p_l72xqjd6e7vo4tuqguhlxctiwm/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_l2qeEvEIae from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.9_@vue+compiler-core@3.4.21_nuxt@3.10.3_p_l72xqjd6e7vo4tuqguhlxctiwm/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_J38SWKjCzG from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.2_@nuxt+devtools@1.3.9_@vue+compiler-core@3.4.21_nuxt@3.10.3__i3i5lw6rics6yyceikb6d7reg4/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_3qWfv96ZcZ from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.2_@nuxt+devtools@1.3.9_@vue+compiler-core@3.4.21_nuxt@3.10.3__i3i5lw6rics6yyceikb6d7reg4/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
import apollo_XzjhUCkOR8 from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_@nuxt+devtools@1.3.9_@opentelemetry+api@1.9.0_@vue+com_atz6q6anvu6dlyp6cxek5budmy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/apollo.ts";
import auth_K2wYduCPEF from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_@nuxt+devtools@1.3.9_@opentelemetry+api@1.9.0_@vue+com_atz6q6anvu6dlyp6cxek5budmy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/auth.ts";
import dynamic_vw_client_kS7b5RjaKG from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/plugins/dynamic-vw.client.ts";
import vue3_toastify_OGYNDsiW9E from "/data/www/uitvaart-platform.nl/uitvaart-platform/nuxt/plugins/vue3-toastify.ts";
export default [
  revive_payload_client_XbULHBwxnS,
  unhead_siqv8KOX3t,
  router_odDsqjRY5H,
  _0_siteConfig_iSHE8CLQVg,
  payload_client_FsJ3jvDwFk,
  check_outdated_build_client_ymhhIsVcrJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_yABJtxGMhs,
  plugin_peuFlAheSI,
  i18n_dFAZsra3L8,
  formkitPlugin_pZqjah0RUG,
  plugin_CbMgn1kqLr,
  plugin_pVADGiWzNI,
  plugin_client_UI7we2nkjn,
  plugin_48LQtm3JhV,
  chunk_reload_client_LW2wQerJ75,
  errors_5QPuds9rY2,
  i18n_l2qeEvEIae,
  apollo_J38SWKjCzG,
  sofie_required_version_3qWfv96ZcZ,
  apollo_XzjhUCkOR8,
  auth_K2wYduCPEF,
  dynamic_vw_client_kS7b5RjaKG,
  vue3_toastify_OGYNDsiW9E
]