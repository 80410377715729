<template>
  <FormkitEditorInternal
    :id="context.id"
    :actions-after="context.attrs['actions-after']"
    :classes="context.classes"
    :placeholder="placeholder"
    :model-value="context._value"
    :name="context.node.name"
    @blur="context.handlers.blur"
    @update:model-value="context.node.input($event)"
  />
</template>

<script setup lang="ts">
import type {FormKitFrameworkContext} from '@formkit/core';

const props = defineProps<{
  context: FormKitFrameworkContext
}>();

const placeholder = computed(() => props.context.attrs.placeholder ?? '');
</script>
