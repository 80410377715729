<template>
  <div class="mx-auto max-w-screen-2xl px-2 md:px-5 lg:px-8">
    <div class="relative z-10 mx-auto max-w-screen-2xl px-2 md:px-5 lg:px-8">
      <div class="flex min-h-[240px] items-center rounded-3xl rounded-bl-[3.75rem] bg-primary px-3 py-8 text-white md:rounded-4xl md:rounded-bl-[5rem] md:p-10 md:pl-12">
        <div class="w-full">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
