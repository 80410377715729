<template>
  <div>
    <NuxtLayout>
      <div class="py-20">
        <AppBannerSubpage>
          <h1>{{ errorMessage.title }}</h1>
          <p>{{ errorMessage.content }}</p>

          <UiButton
            class="mt-10"
            label="Ga terug"
            @click="handleError"
          />
        </AppBannerSubpage>
      </div>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
const currentError = useError();
const error = ref(currentError.value);

watchEffect(() => {
  error.value = currentError.value?.statusCode ? currentError.value : error.value;
});

const errorMessage = computed(() => ({
  '404': {
    title: 'Pagina niet gevonden',
    content: 'De pagina die je probeerde te bezoeken bestaat niet (meer).',
  },
})['' + error.value?.statusCode] ?? {
  title: 'Interne fout',
  content: 'Er ging iets mis op de pagina die je probeerde te bezoeken, ververs de pagina of probeer het op een later moment nog een keer.',
});

function handleError() {
  clearError({
    redirect: '/',
  });
}
</script>
